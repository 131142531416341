export const wms = "https://www.wms.nrw.de/wms/vup_info_meldungen_test";
// export const wms = "https://www.wms.nrw.de/wms/vup_info_meldungen";
export const mapnrw = "https://map.nrw/v200/init.js";

export const API = Object.freeze({
    url: 'https://www.gistest1.nrw.de/grs/rest/vup_info_meldungen_test',
    // url: 'https://www.gis-rest.nrw.de/grs/rest/vup_info_meldungen',

    getMessages: '/meldung/select.json',
    getMessagesByKn: '/meldung/selectbykn.json',
    addMessage: '/meldung/insert.json',
    deleteMessage: '/meldung/deletebyid.json',
    updateMessage: '/meldung/updatebyid.json',
    getProducts: '/produkt/select.json',
    updateProduct: '/produkt/updatebykn.json',
    getBehoerden: '/behoerde/select.json',
    updateBehoerden: '/behoerde/updatebykn.json',
});

export const betrieb =
    {kn: "05000000"};

export const produkte =
    [
        {name: "ALKIS Bestandsdaten mit Eigentümerinformationen", value: "alkisbestandsdatenmiteigentuemerinfo"},
        {name: "ALKIS Bestandsdaten ohne Eigentümerinformationen", value: "alkisbestandsdatenohneeigentuemerinfo"},
        {name: "ALKIS Bestandsdaten nur Punkte", value: "alkisbestandsdatennurpunkte"},
        {name: "Risse", value: "risse"},
        {name: "Grenzniederschriften", value: "grenzniederschriften"},
        {name: "AP Übersichten", value: "apuebersichten"},
        {name: "AP Beschreibungen", value: "apbeschreibungen"},
        {name: "AP Karten", value: "apkarten"},
        {name: "Punktnummernreservierung", value: "punktnrres"},
        {name: "Upload von Vermessungsschriften", value: "upload"},
        {name: "Open Data", value: "opendata"}
    ];

export const bestellformular =
    [
        {
            type: "text-field",
            label: "Nutzername",
            model: "user",
            usergroup: "vermessungsstellen",
            cols: 4,
            class: 'pt-3 pb-3',
        },
        {
            type: "text-field",
            label: "Passwort",
            model: "pwd",
            inputtype: 'password',
            usergroup: "vermessungsstellen",
            cols: 4,
        },
        {
            type: "textarea",
            label: "Auswahlpolygon (Format GeoJSON)",
            model: "gp_gc_session_auswahlpolygon",
            usergroup: "allgemein",
            value: "{\"type\": \"polygon\", \"koordinaten\": [[]]}",
            class: 'pt-6 pb-2',
            cols: 6,
        },
    ];


export const visibility = new Map([
    ['allgemein', 'Allgemein'],
    ['opendata', 'Open Data'],
    ['vermessungsstellen', 'registrierte Vermessungsstellen'],
    ['meldeportal', 'Meldeportal']
]);

export const buttonsData =
    [
        {
            name: "ALKISBestandsdatenMitEigentuemerinfo",
            label: "ALKIS Bestandsdaten mit Eigentümerinformationen",
            visibility: ["vermessungsstellen"]
        },
        {
            name: "ALKISBestandsdatenOhneEigentuemerinfo",
            label: "ALKIS Bestandsdaten ohne Eigentümerinformationen",
            visibility: ["opendata"]
        },
        {
            name: "ALKISBestandsdatenNurPunkteOpenData",
            label: "ALKIS Bestandsdaten nur Punkte (Open Data)",
            visibility: ["opendata"]
        },
        {
            name: "ALKISBestandsdatenNurPunkte",
            label: "ALKIS Bestandsdaten nur Punkte",
            visibility: ["vermessungsstellen"]
        },
        {
            name: "RisseOpenData",
            label: "Risse (Open Data)",
            visibility: ["opendata"]
        },
        {
            name: "Risse",
            label: "Risse",
            visibility: ["vermessungsstellen"]
        },
        {
            name: "Grenzniederschriften",
            label: "Grenzniederschriften",
            visibility: ["vermessungsstellen"]
        },
        {
            name: "APUebersichtenOpenData",
            label: "AP Übersichten (Open Data)",
            visibility: ["opendata"]
        },
        {
            name: "APUebersichten",
            label: "AP Übersichten",
            visibility: ["vermessungsstellen"]
        },
        {

            name: "APBeschreibungenOpenData",
            label: "AP Beschreibungen (Open Data)",
            visibility: ["opendata"]
        },
        {

            name: "APBeschreibungen",
            label: "AP Beschreibungen",
            visibility: ["vermessungsstellen"]
        },
        {
            name: "APKartenOpenData",
            label: "AP Karten (Open Data)",
            visibility: ["opendata"]
        },
        {
            name: "APKarten",
            label: "AP Karten",
            visibility: ["vermessungsstellen"]
        }
    ];
export const buttonsFunction =
    [
        {
            name: "PunktNrRes",
            label: "Punktnummernreservierung",
            visibility: ["vermessungsstellen"]
        },
        {
            name: "Upload",
            label: "Upload von Vermessungsschriften",
            visibility: ["vermessungsstellen"]
        }
    ];

export const icons = new Map([
    ['neue Funktionen', 'mdi-lightbulb-on-outline'],
    ['Feinspezifikation', 'mdi-file-document-outline'],
    ['Benutzerhandbücher', 'mdi-file-document-outline'],
    ['Software-Voraussetzungen', 'mdi-alert-rhombus-outline'],
    ['Download', 'mdi-cloud-download-outline'],
    ['Änderungen', 'mdi-car-brake-alert'],
]);

export const itemsInternerBereich = [
        {
            label: 'Dokumentation<br>Portal',
            icon: 'mdi-file-document',
            items: [
                {
                    titleLeft: "Systemarchitektur Portal Vermessungsunterlagen NRW",
                    expand: false,
                    color: "#c6ebee",
                    colorLabel: '#0E8088',
                    img: {
                        fileName: 'vup_systemarchitektur.png',
                        url: 'https://vermessungsunterlagen.nrw.doi-de.net/intern_download/Systemarchitektur Portal Vermessungsunterlagen NRW.pdf'
                    },
                    table: [
                        {
                            icon: true,
                            heading: 'Feinspezifikation',
                            headingIsBold: true,
                            content: '<a style="text-decoration: none;" ' +
                                ' target="_blank" href="https://vermessungsunterlagen.nrw.doi-de.net/intern_download/220516-PS-SPEC-Feinkonzept_Portal_Vermessungsunterlagen.pdf">' +
                                'Feinspezifikation Portal 6.5.1</a> - die Feinspezifikation beschreibt die Umsetzung des Portals mit Hilfe der Basis-Software ' +
                                'GIS Portal von VertiGIS<br><br>',
                        },
                    ]
                },
                {
                    titleLeft: "Portal Version 6.5.1",
                    titleRight: 'in Produktion seit <b>Juni 2022</b>',
                    expand: true,
                    color: "#ecf8de",
                    colorLabel: '#558B2F',
                    // textTop: {
                    //     class: "colorAlert",
                    //     text: "Aktuell befinden sich noch <b>umfangreiche Änderungen an der Antragsmaske</b> " +
                    //         " sowie weitere Anpassungen in der Fertigstellung durch VertiGIS. Außerdem wird die <b>Portal-Startseite</b> durch IT.NRW vollständig überarbeitet."
                    // },
                    table: [
                        {
                            icon: true,
                            heading: 'neue Funktionen',
                            headingIsBold: false,
                            content: "<ul><li><b>neue Startseite</b> für die optimale Unterstützung der zwei verschiedenen " +
                                "Nutzerkreise inkl. Anzeige der verfügbaren Produkte je Katasterbehörde</li><br/>" +
                                "<li><span style='color:rgb(216, 27, 96)'>" +
                                "<b>für Katasterbehörden mit Online-Adapter ab Version 6.5.1: </b>" +
                                "</span><br> " +
                                "<ul>" +
                                "<li>Bestellung ohne Nutzerkonto, sogenannte <b>Open Data</b>-Bereitstellung</li>" +
                                "<li>Differenzierung der Datenprodukte (z.B. nur Download von Rissen)</li>" +
                                "<li>Upload von Vermessungsschriften durch Vermessungsstellen (optional)</li>" +
                                "</ul><br></li>" +
                                "<li><span style='color:rgb(216, 27, 96)'>" +
                                "Übergabe " +
                                "der <b><u>vollständigen</u> Antragsgeometrie</b> (anstelle - wie aktuell - des Verschneidungsergebnisses " +
                                "mit der Katasteramtsgrenze)<br><br>" +
                                "<b>Achtung:</b> Dadurch ergibt sich eine Änderung der Laufrichtung der Geometrie " +
                                "des Antragspolygons. Es muss sichergestellt werden, " +
                                "dass <u>alle Produktionsadapter</u> der Katasterbehörden mit der neuen Laufrichtung arbeiten können. " +
                                "<br><br>Dies ist <u>unabhängig</u> von der genutzten Version des Online-Adapters!</span>" +
                                "<br/><br/></li><li>" +
                                "Auftragsübersicht: Anzeige der Fehlermeldung bei fehlerhaften Aufträgen</li><li>" +
                                "Auftragsübersicht: initiale Anzeige der letzten 10 Aufträge</li><li>" +
                                "Downloadbereich / Auftragsübersicht: automatische zeitgesteuerte Aktualisierung der Ansicht</li></ul>",
                        },
                        {
                            icon: true,
                            heading: 'Benutzerhandbücher',
                            headingIsBold: true,
                            content: '<ul>' +
                                '<li>' +
                                '<a style="text-decoration: none;" ' +
                                ' target="_blank" href="https://vermessungsunterlagen.nrw.doi-de.net/VUP Benutzerhandbuch_offener Zugang.pdf">' +
                                'Benutzerhandbuch Portal 6.5.1 - offener Zugang (Open Data)</a>' +
                                '</li>' +
                                '<li>' +
                                '<a style="text-decoration: none;" ' +
                                ' target="_blank" href="https://vermessungsunterlagen.nrw.doi-de.net/intern_download/VUP Benutzerhandbuch_geschützter Zugang.pdf">' +
                                'Benutzerhandbuch Portal 6.5.1 - geschützter Zugang</a>' +
                                '</li>' +
                                '<li>' +
                                '<a style="text-decoration: none;" ' +
                                ' target="_blank" href="https://vermessungsunterlagen.nrw.doi-de.net/intern_download/VUP Handbuch Administration BR_6.5.pdf">' +
                                'Handbuch Nutzeradministration 6.5 - Bezirksregierung</a>' +
                                '</li>' +
                                '<li>' +
                                '<a style="text-decoration: none;" ' +
                                ' target="_blank" href="https://vermessungsunterlagen.nrw.doi-de.net/intern_download/VUP Handbuch Administration KA_6.5.pdf">' +
                                'Handbuch Nutzeradministration 6.5 - Katasterbehörde</a>' +
                                '</li>' +
                                '</ul>',
                        },
                    ]
                },
                // {
                //     titleLeft: "Portal Version 6.5.0 - Produktion",
                //     titleRight: 'in Produktion seit <b>März 2020</b>',
                //     expand: true,
                //     color: "#ecf8de",
                //     colorLabel: '#558B2F',
                //     table: [
                //         {
                //             icon: true,
                //             heading: 'neue Funktionen',
                //             headingIsBold: false,
                //             content: "<ul><li>Unterstützung aktueller Basissoftware (Betriebssystem, Applikationsserver, Datenbank, Java)</li><li>" +
                //                 "Änderung des SOAP-Frameworks (Kommunikation mit den Online-Adaptern)</li></ul>",
                //         },
                //         {
                //             icon: true,
                //             heading: 'Benutzerhandbücher',
                //             headingIsBold: true,
                //             content: '<ul>' +
                //                 '<li>' +
                //                 '<a style="text-decoration: none;" ' +
                //                 ' target="_blank" href="https://vermessungsunterlagen.nrw.doi-de.net/intern_download/VUP Benutzerhandbuch_DOI_6.5.pdf">' +
                //                 'Benutzerhandbuch Portal 6.5.0 - DOI</a>' +
                //                 '</li>' +
                //                 '<li>' +
                //                 '<a style="text-decoration: none;" ' +
                //                 ' target="_blank" href="https://vermessungsunterlagen.nrw.doi-de.net/intern_download/VUP Benutzerhandbuch_Internet_6.5.pdf">' +
                //                 'Benutzerhandbuch Portal 6.5.0 - Internet</a>' +
                //                 '</li>' +
                //                 '<li>' +
                //                 '<a style="text-decoration: none;" ' +
                //                 ' target="_blank" href="https://vermessungsunterlagen.nrw.doi-de.net/intern_download/VUP Handbuch Administration BR_6.5.pdf">' +
                //                 'Handbuch Nutzeradministration 6.5.0 - Bezirksregierung</a>' +
                //                 '</li>' +
                //                 '<li>' +
                //                 '<a style="text-decoration: none;" ' +
                //                 ' target="_blank" href="https://vermessungsunterlagen.nrw.doi-de.net/intern_download/VUP Handbuch Administration KA_6.5.pdf">' +
                //                 'Handbuch Nutzeradministration 6.5.0 - Katasterbehörde</a>' +
                //                 '</li>' +
                //                 '</ul>',
                //         },
                //     ]
                // }
            ]
        },
        {
            label: 'Technische<br>Hinweise',
            icon: 'mdi-help-network',
            items: [
                // {
                //     titleLeft: 'Unterbrechung der Antrags-Weiterleitung vom Portal zu den Katasterbehörden',
                //     titleRight: '<b>Produktion</b>',
                //     expand: false,
                //     color: '#FFEBEE',
                //     colorLabel: '#C2185B',
                //     textTop: {
                //         text: "In der aktuellen Version des Portals kommt es gelegentlich zu einem <b>scheinbaren Stillstand der Auftrags-Übermittlung</b>. " +
                //             "Die <u>Erstellung von Anträgen durch die Vermessungsstellen funktioniert problemlos</u>, die Aufträge werden allerdings nicht an die Katasterbehörden übermittelt bzw. es erfolgt keine Statusabfrage und kein Download der Ergebnisdaten." +
                //             "<br><br>" +
                //             "Dies ist im Wesentlichen darin begründet, dass das Portal in der aktuellen Implementierung alle Aufträge <u>nacheinander</u> abarbeitet. In der Regel funktioniert dies auch fehlerfrei. Allerdings führen verschiedene Umstände zeitweise manchmal zum Stau:" +
                //             "<br><br><ul><li>Bestellungen von sehr <b>großen Datenmengen</b> und gleichzeitigen, sporadischen <b>Leitungsengpässen</b></li>" +
                //             "<br><li><b>Verbindungsfehler</b> in der Kommunikation zwischen Portal und Online-Adaptern. Um den Auftragsstatus nicht direkt auf <i>fehlerhaft</i> zu setzen, werden portalseitig in geringem zeitlichen Abstand <u>drei</u> Wiederholungen für den Verbindungsaufbau in der Kommunikation Richtung Katasterbehörde vorgenommen, bevor der Auftrag generell abgebrochen wird.</li></ul><br>" +
                //             "Durch die genannten Gründe kann es zu einer <b>generellen Verzögerung der Auftrags-Übermittlung</b> über alle Katasterbehörden hinweg kommen. IT.NRW überwacht den Übermittlungsprozess allerdings automatisiert und wird über alle Verzögerungen informiert. Sollte ein manueller Eingriff notwendig sein, wird dieser vorgenommen." +
                //             "<br><br>Aktuell ist VertiGIS dabei, die Client-Anpassungen für die Open Data-Variante zu finalisieren.<br>" +
                //             "Im Anschluss wird eine Lösung für die <b>Parallelisierung des Portals</b> erarbeitet (in gewissen Grenzen, um die Stabilität des Portals jederzeit zu gewährleisten), damit das beschriebene Verhalten in Zukunft möglichst auf ein Minimum reduziert wird.</b>"
                //     },
                // },

                {
                    titleLeft: 'Änderung der Laufrichtung der Antragsgeometrie',
                    titleRight: '<b>! seit Umstellung auf Portalversion 6.5.1</b>',
                    expand: false,
                    color: '#FFEBEE',
                    colorLabel: '#C2185B',
                    textTop: {
                        text: "Auf Wunsch der Katasterbehörden erfolgt ab der Portalversion 6.5.1 die Übergabe " +
                            "der <b><u>vollständigen</u> Antragsgeometrie</b> (anstelle - wie aktuell - des Verschneidungsergebnisses " +
                            "mit der Katasteramtsgrenze)<br><br>" +
                            "<b>Achtung:</b> Dadurch ergibt sich eine Änderung der Laufrichtung der Geometrie " +
                            "des Antragspolygons. Vor Umstellung des Landesportals muss sichergestellt werden, " +
                            "dass <u>alle Produktionsadapter</u> der Katasterbehörden mit der neuen Laufrichtung arbeiten können. " +
                            "<br><br><b>Dies ist <u>unabhängig</u> von der genutzten Version des Online-Adapters!</b>"
                    },
                },
                {
                    titleLeft: 'TLS-Zertifikat für den Online-Adapter',
                    titleRight: '<b>Produktion - ab Online-Adapter 6.5.0</b>',
                    expand: false,
                    color: '#FFEBEE',
                    colorLabel: '#C2185B',
                    textTop: {
                        text: "Beim Wechsel der Version des Online-Adapters wird ab Version 6.5.0 <strong><u>auch bei offiziell signierten TLS-Zertifikaten</u></strong> in der Kommunikation zum Online-Adapter überprüft, ob das <b>Zertifikat auf den FQDN (vollständiger Domain-Name) des Online-Adapters</b> ausgestellt ist bzw. in der Liste der <b>Alternativnamen</b> des Zertifikats enthalten ist.<br><br>" +
                            "Sollte dies nicht der Fall sein, ist eine Kommunikation zum Adapter nicht möglich."
                    },
                }
                ,
                {
                    titleLeft: 'Selbst signiertes TLS-Zertifikat für den Online-Adapter',
                    titleRight: '<b>Produktion</b>',
                    expand: false,
                    color: '#ecf8de',
                    colorLabel: '#558B2F',
                    textTop: {
                        text: 'Die Kommunikation zwischen dem Portal Vermessungsunterlagen NRW und den ' +
                            'Online-Adaptern der Katasterbehörden erfolgt verschlüsselt. Die dafür benötigten TLS-Zertifikate haben immer einen ' +
                            '<b>begrenzten Gültigkeitszeitraum</b>.' +
                            '<br><br>Grundsätzlich ist es möglich, den Online-Adapter über ein <b>selbst signiertes</b> TLS-Zertifikat zu verschlüsseln. ' +
                            'Allerdings ist dann ein Import des Zertifikats auf dem Portal-Server bei IT.NRW notwendig! Im Falle von selbst signierten Zertifikaten ist folgendes zu beachten:<br><br>' +
                            '<ul><li>bei "Alternativer Zertifikatantragstellername / Alternative Inhaberbezeichnungen" muss die IP bzw. der DNS-Name eingetragen werden<br><br></li>' +
                            '<li>frühzeitige Benachrichtigung des <a style="text-decoration: none;color:inherit"' +
                            '                                   href="mailto:dirk.grudda@it.nrw.de?cc=andrea.noack@it.nrw.de&subject=Portal Vermessungsunterlagen NRW - Austausch des TLS-Zertifikats">' +
                            '    Geoinformationszentrums von IT.NRW (E-Mail-Link)</a> über den Austausch eines Zertifikates.<span style="color: rgb(216, 27, 96)"> Sollte ein selbst signiertes Zertifikat abgelaufen oder ausgetauscht worden sein, ' +
                            'ohne dass es bei IT.NRW neu importiert wurde, ist <u>keine Kommunikation zwischen Landesportal und Online-Adapter</u> möglich!</span></li></ul>'
                    },
                },
                {
                    titleLeft: 'Absicherung des Online-Adapters per HTTP-BASIC Authentifizierung',
                    titleRight: '<b>Produktion</b>',
                    expand: false,
                    color: '#ecf8de',
                    colorLabel: '#558B2F',
                    textTop: {
                        text: 'Die Kommunikation zwischen dem Portal Vermessungsunterlagen NRW und den ' +
                            'Online-Adaptern der Katasterbehörden erfolgt verschlüsselt und im gesicherten Netz (NdB).<br><br>' +
                            '<span style="color: rgb(216, 27, 96)">Für bestmöglichsten Schutz wird <b>empfohlen</b>, ' +
                            'den Online-Adapter sowie die Download-URL per HTTP-BASIC Authentifizierung (Nutzername & Passwort) abzusichern.</span> Die Zugangsdaten werden durch IT.NRW in die Portalkonfiguration aufgenommen.'
                    },
                },
                {
                    titleLeft: 'Upload von Vermessungsunterlagen - Virenscan',
                    titleRight: '<b>Testbetrieb</b>',
                    expand: false,
                    color: '#f0f0f0',
                    colorLabel: 'rgb(0, 100, 156)',
                    textTop: {
                        text: 'Katasterbehörden, welche den <b>Online-Adapter 6.5.1</b> einsetzen, <u>können</u> den Upload von Unterlagen durch registrierte Vermessungstellen' +
                            ' ermöglichen. Der Upload erfolgt dabei wie folgt:<br><br>' +
                            '<ol><li>Upload einer Datei im Internet: <span style="color: rgb(216, 27, 96)">zugelassene Dateiformate sind *.zip, *.rar, *.gz</span><br><br></li>' +
                            '<li>BSI-konformer Virenscan dieser Datei auf Binärebene.' +
                            ' Während des Scans werden aus Sicherheitsgründen allerdings <span style="color: rgb(216, 27, 96)">weder die Archivdateien noch ggf. darin ' +
                            'enthaltene Container-Dateien entpackt</span>. Dadurch wird der Gefahr einer Systemüberlastung durch ineinander geschachtelte Archive entgegengewirkt. ' +
                            'Es wird somit nur die "äußere" Datei auf bekannte Schwachstellen überprüft. Alle inneren Dateien müssen vor Ort bei der Entpackung geprüft werden!<br><br></li>' +
                            '<li>Sollte eine Datei im Virenscan als Gefährdung eingestuft werden, wird sie automatisch unter Quarantäne gestellt und die IT-Sicherheit von IT.NRW informiert.<br><br></li>' +
                            '<li>Mit einer kurzen zeitlichen Verzögerung erfolgt vom gesicherten Netz (NdB) aus der Zugriff auf die Datei ' +
                            'sowie die Weiterleitung an die ausgewählten Katasterbehörden. Sollte keine Datei auf dem Internet-Server vorliegen, kann von einem Virenverdacht ausgegangen werden. In diesem Fall wird ' +
                            'vom Portal aus zunächst das Geoinformationszentrum von IT.NRW informiert und kann entsprechend darauf reagieren.</li></ol>'
                    },
                },
            ]
        },
        {
            label: 'Metadaten<br>Katasterbehörde',
            icon: 'mdi-information',
            textTop: {
                text: '<div class="mb-6">Für den <b>Anschluss einer' +
                    '   Katasterbehörde am Portal</b> werden verschiedene' +
                    '   Informationen benötigt, welche zentral im System durch IT.NRW konfiguriert werden.<br><br>' +
                    '   Diese unterscheiden sich je nach Version des verwendeten Online-Adapters und sind zum Teil verpflichtend' +
                    '   (<b style="color:rgb(216, 27, 96)">*</b>).<br>' +
                    '   Bitte setzen Sie sich mit dem<a style="text-decoration: none;color:inherit"' +
                    '                                   href="mailto:dirk.grudda@it.nrw.de?cc=andrea.noack@it.nrw.de&subject=Portal Vermessungsunterlagen NRW - Konfiguration der Katasterbehörde">' +
                    '    ' +
                    '    Geoinformationszentrum von IT.NRW (E-Mail-Link)</a> in Verbindung, wenn einzelne Parameter geändert werden sollen.' +
                    '  </div>'
            },
            items: [
                {
                    titleLeft: "Online-Adapter 6.5.1",
                    titleRight: '<b>in Produktion</b>',
                    expand: true,
                    color: "#ecf8de",
                    colorLabel: '#558B2F',
                    tableHeadingColSm: 5,
                    tableHeadingColLg: 5,
                    table: [
                        {
                            heading: 'Name <span style="color:rgb(216, 27, 96)"> *</span>',
                            headingIsBold: true,
                            class: 'pt-2 pb-0',
                            content: "Name der Katasterbehörde zur Darstellung in der Oberfläche des Portals",
                        },
                        {
                            heading: 'URL Online-Adapter <span style="color:rgb(216, 27, 96)"> *</span>',
                            headingIsBold: true,
                            class: 'pt-4 pb-0',
                            content: "URL des Online-Adapters der Katasterbehörde",
                        },
                        // {
                        //     heading: 'URL Online-Adapter Download <span style="color:rgb(216, 27, 96)"> *</span>',
                        //     headingIsBold: true,
                        //     class: 'py-1',
                        //     content: "URL des Online-Adapters zum Download der Produktionsergebnisse",
                        // },
                        // {
                        //     heading: 'URL Online-Adapter Upload',
                        //     headingIsBold: true,
                        //     class: 'pt-4 pb-0',
                        //     content: "URL des Online-Adapters für den Upload von Vermessungsschriften registrierter Nutzer",
                        // },
                        {
                            heading: 'Maximale Dateigröße Upload',
                            headingIsBold: true,
                            class: 'py-1',
                            content: "Maximale Dateigröße des Uploads an die Katasterbehörde in Megabytes",
                        },
                        {
                            heading: 'Zugangsdaten Online-Adapter <span style="color:rgb(216, 27, 96)">(empfohlen)</span>',
                            headingIsBold: true,
                            class: 'pt-4 pb-0',
                            content: "Nutzername und Passwort für mittels HTTP-Authentifizierung (BASIC) abgesicherte Online-Adapter",
                        },
                        {
                            heading: 'Adapter-Version <span style="color:rgb(216, 27, 96)"> *</span>',
                            headingIsBold: true,
                            class: 'py-1',
                            content: "Version des eingesetzten Online-Adapters",
                        },
                        {
                            heading: 'E-Mail-Adresse für Kopie der Anfrage',
                            headingIsBold: true,
                            class: 'pt-4 pb-0',
                            content: "E-Mail-Adresse für Katasterbehörden, die einen Auftrag als Kopie per E-Mail wünschen",
                        },
                        {
                            heading: 'E-Mail-Adresse für Fehler',
                            headingIsBold: true,
                            class: 'py-1',
                            content: "E-Mail-Adresse der Katasterbehörde für den Fall, dass ein Auftrag fehlschlägt",
                        },
                        {
                            heading: 'E-Mail-Adresse für Kopie der Erfolgsmeldung an den Kunden',
                            headingIsBold: true,
                            class: 'py-1',
                            content: "E-Mail-Adresse der Katasterbehörde, welche die E-Mail über erfolgreiche Bearbeitung als Kopie wünschen",
                        },
                        {
                            heading: 'E-Mail-Adresse für Info-E-Mail über den Upload',
                            headingIsBold: true,
                            class: 'py-1',
                            content: "E-Mail-Adresse der Katasterbehörde für den Upload von Vermessungsschriften durch registrierte Vermessungsstellen",
                        },
                        {
                            heading: 'Internet-Adresse für Infoseite',
                            headingIsBold: true,
                            class: 'pt-4 pb-0',
                            content: "URL einer Informationsseite der Katasterbehörde - diese wird in der Antragsmaske verlinkt",
                        }, {
                            heading: 'Infotext',
                            headingIsBold: true,
                            class: 'py-1',
                            content: "Testbaustein der Katasterbehörde zur Anzeige in der Antragsmaske",
                        }, {
                            heading: 'Produkt Grenzniederschriften <span style="color:rgb(216, 27, 96)"> *</span>',
                            headingIsBold: true,
                            class: 'pt-4 pb-0',
                            content: "Verfügbarkeit  (ja/nein)",
                        }, {
                            heading: 'Produkt ALKIS Bestandsdaten mit Eigentümerinfo <span style="color:rgb(216, 27, 96)"> *</span>',
                            headingIsBold: true,
                            class: 'py-1',
                            content: "Verfügbarkeit  (ja/nein)",
                        }, {
                            heading: 'Produkt ALKIS Bestandsdaten ohne Eigentümerinfo <span style="color:rgb(216, 27, 96)"> *</span>',
                            headingIsBold: true,
                            class: 'py-1',
                            content: "Verfügbarkeit  (ja/nein)",
                        }, {
                            heading: 'Produkt ALKIS Bestandsdaten nur Punkte <span style="color:rgb(216, 27, 96)"> *</span>',
                            headingIsBold: true,
                            class: 'py-1',
                            content: "Verfügbarkeit  (ja/nein)",
                        }, {
                            heading: 'Produkt AP Übersichten <span style="color:rgb(216, 27, 96)"> *</span>',
                            headingIsBold: true,
                            class: 'py-1',
                            content: "Verfügbarkeit  (ja/nein)",
                        }, {
                            heading: 'Produkt AP Beschreibungen <span style="color:rgb(216, 27, 96)"> *</span>',
                            headingIsBold: true,
                            class: 'py-1',
                            content: "Verfügbarkeit  (ja/nein)",
                        }, {
                            heading: 'Produkt AP Karten <span style="color:rgb(216, 27, 96)"> *</span>',
                            headingIsBold: true,
                            class: 'py-1',
                            content: "Verfügbarkeit  (ja/nein)",
                        }, {
                            heading: 'Produkt Risse <span style="color:rgb(216, 27, 96)"> *</span>',
                            headingIsBold: true,
                            class: 'py-1',
                            content: "Verfügbarkeit  (ja/nein)",
                        }, {
                            heading: 'Funktion Punktnummernreservierung <span style="color:rgb(216, 27, 96)"> *</span>',
                            headingIsBold: true,
                            class: 'py-1',
                            content: "Verfügbarkeit  (ja/nein)",
                        }
                    ]
                },
                {
                    titleLeft: "Online-Adapter 6.4.3 und 6.5.0",
                    titleRight: '<b>in Produktion</b>',
                    expand: true,
                    color: "#ecf8de",
                    colorLabel: '#558B2F',
                    tableHeadingColSm: 5,
                    tableHeadingColLg: 5,
                    table: [
                        {
                            heading: 'Name <span style="color:rgb(216, 27, 96)"> *</span>',
                            headingIsBold: true,
                            class: 'pt-2 pb-0',
                            content: "Name der Katasterbehörde zur Darstellung in der Oberfläche des Portals",
                        },
                        {
                            heading: 'URL Online-Adapter <span style="color:rgb(216, 27, 96)"> *</span>',
                            headingIsBold: true,
                            class: 'pt-4 pb-0',
                            content: "URL des Online-Adapters der Katasterbehörde",
                        },
                        {
                            heading: 'URL Online-Adapter Download <span style="color:rgb(216, 27, 96)"> *</span>',
                            headingIsBold: true,
                            class: 'py-1',
                            content: "URL des Online-Adapters zum Download der Produktionsergebnisse",
                        },
                        {
                            heading: 'Zugangsdaten Online-Adapter <span style="color:rgb(216, 27, 96)">(empfohlen)</span>',
                            headingIsBold: true,
                            class: 'py-1',
                            content: "Nutzername und Passwort für mittels HTTP-Authentifizierung (BASIC) abgesicherte Online-Adapter",
                        },
                        {
                            heading: 'Adapter-Version <span style="color:rgb(216, 27, 96)"> *</span>',
                            headingIsBold: true,
                            class: 'py-1',
                            content: "Version des eingesetzten Online-Adapters",
                        },
                        {
                            heading: 'E-Mail-Adresse für Kopie der Anfrage',
                            headingIsBold: true,
                            class: 'pt-4 pb-0',
                            content: "E-Mail-Adresse für Katasterbehörden, die einen Auftrag als Kopie per E-Mail wünschen",
                        },
                        {
                            heading: 'E-Mail-Adresse für Fehler',
                            headingIsBold: true,
                            class: 'py-1',
                            content: "E-Mail-Adresse der Katasterbehörde für den Fall, dass ein Auftrag fehlschlägt",
                        },
                        {
                            heading: 'Internet-Adresse für Infoseite',
                            headingIsBold: true,
                            class: 'pt-4 pb-0',
                            content: "URL einer Informationsseite der Katasterbehörde - diese wird in der Antragsmaske verlinkt",
                        }, {
                            heading: 'Produkt Grenzniederschriften <span style="color:rgb(216, 27, 96)"> *</span>',
                            headingIsBold: true,
                            class: 'pt-4 pb-0',
                            content: "Verfügbarkeit  (ja/nein)",
                        }, {
                            heading: 'Produkt NAS Daten <span style="color:rgb(216, 27, 96)"> *</span>',
                            headingIsBold: true,
                            class: 'py-1',
                            content: "Verfügbarkeit  (ja/nein)",
                        }, {
                            heading: 'Funktion Punktnummernreservierung <span style="color:rgb(216, 27, 96)"> *</span>',
                            headingIsBold: true,
                            class: 'py-1',
                            content: "Verfügbarkeit  (ja/nein)",
                        }
                    ]
                }
            ]
        },
        {
            label: 'Download<br>Online-Adapter',
            icon: 'mdi-cloud-download',

            items: [
                {
                    titleLeft: "Test-Aufrufe für Online- und Produktionsadapter",
                    titleRight: 'für die Entwicklung bei den Katasterbehörden',
                    expand: false,
                    color: "#f0f0f0",
                    colorLabel: 'rgb(0, 100, 156)',
                    img: {
                        fileName: 'Beispielrequest_Linkbase_OA_6_5_1_soapui.jpg'
                    },
                    textTop: {
                        text: '<div>Für den <b>Test der' +
                            '   Online- und Produktionsadapter</b> kann beispielsweise die Open Source-Software <a style="text-decoration:none;color:inherit; font-weight: bolder"' +
                            '                                   href="https://www.soapui.org/">' +
                            '    ' +
                            '    SoapUI</a> (externer Link)' +
                            '   genutzt werden. <br>Damit können die Adapter unabhängig vom Portal getestet werden.<br><br>' +
                            '   ' +
                            '   <ul><li><a style="text-decoration:none;color:inherit" ' +
                            'href="https://vermessungsunterlagen.nrw.doi-de.net/intern_download/Beispielrequest_Linkbase_OA_6_5_1.xml">' +
                            'Beispiel-Request für Linkbase - Online-Adapter Version 6.5.1</a></li>' +
                            '<li><a style="text-decoration:none;color:inherit" ' +
                            'href="https://vermessungsunterlagen.nrw.doi-de.net/intern_download/Beispielrequest_ibR_OA_6_5_1.xml">' +
                            'Beispiel-Request für ibR - Online-Adapter Version 6.5.1</a></li></ul>' +
                            '</div>'
                    }
                },
                {
                    titleLeft: "Online-Adapter Version 6.5.1",
                    titleRight: 'in Produktion seit <b>Juni 2022</b>',
                    expand: true,
                    color: "#ecf8de",
                    colorLabel: '#558B2F',
                    textTop: {
                        class: "colorAlert",
                        text: "<b>Alle</b> angeschlossenen Produktions- und Geschäftsbuch-Adapter müssen die <b>SOAP-Schnittstelle 2.1.0</b> bedienen!"
                    },
                    table: [
                        {
                            icon: true,
                            heading: 'neue Funktionen',
                            headingIsBold: false,
                            content: "<ul><li>Upload von Vermessungsschriften durch Vermessungsstellen (optional)</li><br/>" +
                                "<li><b>Voraussetzung für Open Data-Bereitstellung:</b> <br>Differenzierung der Datenprodukte (z.B. nur Download von Rissen)</li></ul>",
                        },
                        {
                            icon: true,
                            heading: 'Software-Voraussetzungen',
                            headingIsBold: false,
                            content: "<ul><li>MySQL Server 8.0.x</li>" +
                                "<li>Java 11 oder höher <br><i>zur Info: mindestens bei einer Katasterbehörde mit openjdk-15 im Einsatz</i></li>" +
                                "<li>Apache Tomcat 9.0.x</li></ul>",
                        },
                        {
                            icon: true,
                            heading: 'Download',
                            headingIsBold: true,
                            content: '<ul>' +
                                '<li>' +
                                '<a style="text-decoration: none;" ' +
                                'href="https://vermessungsunterlagen.nrw.doi-de.net/intern_download/VermUnterlagenPortalOnlineAdapter-6-5-1-3.zip">' +
                                'Online-Adapter 6.5.1 Software</a>' +
                                '</li>' +
                                '<li>' +
                                '<a style="text-decoration: none;" ' +
                                'href="https://vermessungsunterlagen.nrw.doi-de.net/intern_download/Administrationshandbuch Online-Adapter-6-5-1-3.pdf">' +
                                'Online-Adapter 6.5.1 Administrationshandbuch</a>' +
                                '</li>' +
                                '<li>' +
                                '<a style="text-decoration: none;" ' +
                                'href="https://vermessungsunterlagen.nrw.doi-de.net/intern_download/PortaladapterWebservice_2.1.0.wsdl">' +
                                'Schnittstellenbeschreibung SOAP Webservice Portaladapter 2.1.0</a>' +
                                '</li>' +
                                '<li>' +
                                '<a style="text-decoration: none;" ' +
                                'href="https://vermessungsunterlagen.nrw.doi-de.net/intern_download/Auftrag_6_5_1.json">' +
                                'Beispiel auftrag.json 6.5.1</a>' +
                                '</li>' +
                                '</ul>',
                        },
                    ]
                },
                {
                    titleLeft: "Online-Adapter Version 6.5.0",
                    titleRight: 'in Produktion seit <b>März 2020</b>',
                    expand: false,
                    color: "#ecf8de",
                    colorLabel: '#558B2F',
                    textTop: {
                        class: "colorAlert",
                        text: "<b>Alle</b> angeschlossenen Produktions- und Geschäftsbuch-Adapter müssen die <b>SOAP-Schnittstelle 2.0.0</b> bedienen!"
                    },
                    table: [
                        {
                            icon: true,
                            heading: 'neue Funktionen',
                            headingIsBold: false,
                            content: "<ul><li>Änderung des SOAP-Frameworks</li><br/>" +
                                "<li>Unterstützung aktueller Basissoftware (Java und MySQL)</li></ul>",
                        },
                        {
                            icon: true,
                            heading: 'Software-Voraussetzungen',
                            headingIsBold: false,
                            content: "<ul><li>MySQL Server 8.0.x</li>" +
                                "<li>Java 11 oder höher <br><i>zur Info: mindestens bei einer Katasterbehörde mit openjdk-15 im Einsatz</i></li>" +
                                "<li>Apache Tomcat 9.0.x</li></ul>",
                        },
                        {
                            icon: true,
                            heading: 'Änderungen',
                            headingIsBold: false,
                            content: '<div style="color:rgb(216, 27, 96)">6.5.0.1</div>' +
                                '<ul><li>Fehlerbehebung: "Auftrag wird trotz Fehler mind. eines Produktionsadapters weiter verarbeitet"</li></ul>',
                        },
                        {
                            icon: true,
                            heading: 'Download',
                            headingIsBold: true,
                            content: '<ul>' +
                                '<li>' +
                                '<a style="text-decoration: none;" ' +
                                'href="https://vermessungsunterlagen.nrw.doi-de.net/intern_download/VermUnterlagenPortalOnlineAdapter-6-5-0-1.zip">' +
                                'Online-Adapter 6.5.0.1 Software</a>' +
                                '</li>' +
                                '<li>' +
                                '<a style="text-decoration: none;" ' +
                                'href="https://vermessungsunterlagen.nrw.doi-de.net/intern_download/Administrationshandbuch Online-Adapter-6-5-0-1.pdf">' +
                                'Online-Adapter 6.5.0.1 Administrationshandbuch</a>' +
                                '</li>' +
                                '<li>' +
                                '<a style="text-decoration: none;" ' +
                                'href="https://vermessungsunterlagen.nrw.doi-de.net/intern_download/PortaladapterWebservice_2.0.0.wsdl">' +
                                'Schnittstellenbeschreibung SOAP Webservice Portaladapter 2.0.0</a>' +
                                '</li>' +
                                '<li>' +
                                '<a style="text-decoration: none;" ' +
                                'href="https://vermessungsunterlagen.nrw.doi-de.net/intern_download/Auftrag_6_5_0.json">' +
                                'Beispiel auftrag.json 6.5.0</a>' +
                                '</li>' +
                                '</ul>',
                        },
                    ]
                },
                {
                    titleLeft: "Online-Adapter Version 6.4.3",
                    expand: false,
                    color: "#ecf8de",
                    colorLabel: '#558B2F',

                    table: [
                        {
                            icon: true,
                            heading: 'Software-Voraussetzungen',
                            headingIsBold: false,
                            content: "<ul><li>MySQL Server 5.7.10</li>" +
                                "<li>Java J2SDK 1.8.x</li>" +
                                "<li>Apache Tomcat 9.0.x</li></ul>",
                        },
                        {
                            icon: true,
                            heading: 'Download',
                            headingIsBold: true,
                            content: '<ul>' +
                                '<li>' +
                                '<a style="text-decoration: none;" ' +
                                'href="https://vermessungsunterlagen.nrw.doi-de.net/intern_download/VermUnterlagenPortalOnlineAdapter-6-4-3-3.zip">' +
                                'Online-Adapter 6.4.3.3 Software</a>' +
                                '</li>' +
                                '<li>' +
                                '<a style="text-decoration: none;" ' +
                                'href="https://vermessungsunterlagen.nrw.doi-de.net/intern_download/Administrationshandbuch Online-Adapter-6-4-3-3.pdf">' +
                                'Online-Adapter 6.4.3.3 Administrationshandbuch</a>' +
                                '</li>' +
                                '</ul>',
                        },
                    ]
                }
            ]
        },
        {
            label: 'Übersicht<br>Fachadapter',
            icon: 'mdi-lan',
            textTop: {
                text: '<div class="mb-6 mt-10">Die <strong>Übersicht der Fachadapter</strong> soll dabei helfen, den gegenseitigen Austausch zwischen den Behörden bei Fragen und Problemen rund um die Fachadapter zu unterstützen.' +
                    '   <br><br>' +
                    '   Wenn Ihre Behörde hier mit aufgenommen werden soll, melden Sie bitte die genutzten Fachadapter sowie die eingesetzte Version des Online-Adapters an das <a style="text-decoration: none;color:inherit"' +
                    '                                   href="mailto:dirk.grudda@it.nrw.de?cc=andrea.noack@it.nrw.de&subject=Portal Vermessungsunterlagen NRW - Fachadapter der Katasterbehörde">' +
                    '    ' +
                    '    Geoinformationszentrum von IT.NRW (E-Mail-Link)</a>.' +
                    '  </div>'
            },
        },
        {
            label: 'Wartungs- und <br>Störungsmeldungen',
            icon: 'mdi-alert-outline',
            textTop: {
                text: '<div class="mb-6 mt-10">' +
                    'Über folgende Seite können Sie <strong>Wartungs- und Störungsmeldungen Ihrer Behörde</strong> pflegen: <br>' +
                    '<ul class="mt-2 mb-4"><li><strong>direkter Zugriff</strong> mit Angabe des Kreisschlüssels am Beispiel von Köln: <a href="' + window.location.origin + '/meldeseite/05315000" target="_blank">' + window.location.origin + '/meldeseite/05315000</a></li></ul>' +
                    'Sie können angeben, ob es sich um eine <strong>Wartung oder Störung</strong> handelt und <strong>für welchen Nutzerkreis</strong> die Meldung sichtbar sein soll.' +
                    '<br><br>Die Meldungen werden je nach Nutzerkreis auf der Login-Seite bzw. den allgemeinen Informationsseiten angezeigt. Außerdem erfolgt eine Anzeige innerhalb der Antragsmaske nach Auswahl des Antragsgebiets.</div>'
            },
        },
    ]
;